import React from "react"
import { Layout, Head, Breadcrumbs } from "@common"
import { TherapiesTopSlider, SingleProduct } from "@pages"
import { BlogSection, CallTherapistSection, NewsletterSection } from "@sections"
import { useStaticQuery, graphql, Link } from "gatsby"

import "./styles/products.scss"

const Products = ({ pageContext, location }) => {
  const { products, posts, page, categories } = pageContext
  let coupon = null
  if (location.search.includes("coupon") || location.search.includes("ref")) {
    coupon = location.search.split("=")[1]
  }
  if (coupon != null) {
    localStorage.setItem("urlCoupon", coupon)
    window.history.replaceState(null, null, location.pathname)
  }
  const { WP } = useStaticQuery(graphql`
    query {
      WP {
        pageBy(uri: "categories/") {
          featuredImage {
            altText
            sizes
            sourceUrl
            srcSet
          }
        }
      }
    }
  `)
  const { featuredImage } = WP.pageBy
  return (
    <Layout
      title={page.name}
      subtitle={page.description}
      parallaxImg={featuredImage}
    >
      <Head
        seo={pageContext.page.seo}
        siteMetaData={pageContext.page.siteMetaData}
      />
      <div className="products">
        <div className="products-content">
          <div className="container">
            <Breadcrumbs
              elements={[
                { label: "Skarby", url: "/categories/" },
                {
                  label: page.name,
                  url: `/categories/${page.slug}/`,
                },
              ]}
            />
            <TherapiesTopSlider categories={categories} />
            <div className="products-list">
              {products.length === 0 && (
                <div className="product-item-message">
                  <h5 className="products-content-title">
                    Brak produktów w tej kategorii
                  </h5>
                </div>
              )}
              {products.map((el, k) => (
                <SingleProduct {...el} key={k} />
              ))}
            </div>
          </div>
        </div>
        <CallTherapistSection />
        <BlogSection posts={posts} />
        <NewsletterSection />
      </div>
    </Layout>
  )
}

export default Products
